body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-menu-horizontal > li:nth-child(2) {
  flex: 1 !important;
}

.ant-menu-horizontal > li:nth-child(1) {
  padding-left: 0 !important;
}

.ant-menu-horizontal > li:nth-child(1)::after,
.ant-menu-horizontal > li:nth-child(2)::after {
  border-bottom: none !important;
}

.ant-menu-horizontal > li:nth-child(1):hover::after,
.ant-menu-horizontal > li:nth-child(2):hover::after {
  border-bottom: none !important;
}

.ant-menu-horizontal > li:nth-child(2):hover,
.ant-menu-horizontal > li:nth-child(2).ant-menu-item-active,
.ant-menu-horizontal > li:nth-child(2)::after {
  cursor: auto !important;
}

.ant-menu-horizontal > li {
  height: 46px !important;
}

.ant-menu-horizontal > li:hover::after {
  border-bottom-color: white !important;
}

.ant-menu-horizontal > .ant-menu-item-selected::after {
  border-bottom-color: white !important;
}

/* Add these new styles */
.scrolled.ant-menu-horizontal > li {
  color: #333 !important; /* Darker text color when scrolled */
}

.scrolled.ant-menu-horizontal > li:hover::after,
.scrolled.ant-menu-horizontal > .ant-menu-item-selected::after {
  border-bottom-color: #333 !important; /* Darker border color when scrolled */
}
