* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

.hero-section {
  height: 800px;
  background-color: rgba(42, 50, 64, 0.7);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: white;
  flex-direction: column;
  background-image: url(https://ik.imagekit.io/petpals/landing-page/cover.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 0;
  width: 100%;
}

.container {
  max-width: 1160px;
  width: 100%;
  margin: 0 auto;
}

.hero-content {
  max-width: 550px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.hero-title {
  color: #ffffff;
  text-shadow: 1px 1px 2px #000000;
}

.hero-title span {
  font-size: 80px;
}

.hero-subtitle {
  color: #ffffff;
  font-weight: 400;
  text-shadow: 1px 1px 2px #000000;
}

.button-container {
  width: 100%;
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.primary-button {
  background-color: #2a3240;
  border-color: #2a3240;
  box-shadow: 0 0 1px #ffffff;
}

.primary-button:hover {
  background-color: #2a3240 !important;
  border-color: #2a3240 !important;
}

.section {
  max-width: 1160px;
  width: 100%;
  min-height: 500px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.half-width {
  width: 50%;
}

.features-title {
  text-align: center;
  color: #2a3240;
  font-size: 50px;
}

.features-section {
  max-width: 1160px;
  width: 100%;
  min-height: 500px;
  margin: 0 auto;
}

.feature-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.text-right {
  text-align: right;
}

.dark-section {
  min-height: 500px;
  background-color: #2a3240;
  margin-top: 40px;
}

.dark-section .container {
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dark-content {
  width: 50%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.app-badges {
  display: flex;
  gap: 25px;
  margin-top: 20px;
}

.early-access-section {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.early-access-section .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.early-access-content {
  width: 500px;
  text-align: center;
}

.early-access-title {
  color: #2a3240;
  font-size: 40px;
}

.email-signup {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.contact-section {
  max-width: 1160px;
  width: 100%;
  height: 300px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-content {
  text-align: center;
  width: 50%;
}

.contact-email {
  color: #2a3240;
}

.footer {
  background-color: #2a3240;
}

.footer-logo {
  width: 50px;
  height: auto;
}

.dropdown-collapse {
  width: 100%;
}

.dropdown-collapse ul {
  padding-left: 20px;
  margin: 20px 0;
}

.text-h4 {
  font-weight: 400;
  color: #2a3240;
}

.error-message {
  color: red;
  margin-top: 10px;
  height: 21px;
  font-size: 14px;
}

.email-input-container {
  display: flex;
  flex-direction: column;
}

.early-access-button:disabled {
  color: #747474;
}

@media (max-width: 1180px) {
  .logo-container {
    margin-left: 20px;
  }

  .contact-link {
    margin-right: 20px;
  }

  .hero-content {
    width: 100%;
  }

  .section .half-width,
  .features-section .half-width {
    order: 2;
  }

  .hero-section .container {
    padding: 0 20px;
  }

  .section,
  .feature-row {
    flex-direction: column;
    text-align: center;
    padding-top: 40px;
  }

  .text-right {
    text-align: center;
  }

  .dropdown-collapse {
    width: 50%;
    margin: 40px auto 0;
  }

  .section h1,
  .feature-row h1 {
    margin-bottom: 20px;
  }

  .features-title {
    margin: 40px 0;
  }

  .dark-section {
    padding: 40px 0;
  }

  .dark-section .container {
    flex-direction: column;
    text-align: center;
  }

  .dark-content .app-badges {
    margin: 20px auto;
  }

  .dark-content {
    width: 70%;
  }
}

@media (max-width: 880px) {
  .dropdown-collapse,
  .half-width,
  .early-access-content,
  .contact-content {
    width: 90%;
  }

  .email-signup {
    flex-direction: column;
  }

  .early-access-section {
    height: fit-content;
    padding: 60px 0;
  }
}
